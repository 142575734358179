/*
 * Essential Imports
 */

import 'picturefill';
import 'lazysizes';
import './vendor/ls.object-fit';


/*
 * Essentail Fixes
 */

// Preload and No-JS
setTimeout(function () {
    document.documentElement.classList.remove('preload');
    document.documentElement.classList.remove('no-js');
}, 300);

let resizeId;
window.addEventListener('resize', function () {
    clearTimeout(resizeId);
    document.documentElement.classList.add('preload');
    resizeId = setTimeout(function () {
        document.documentElement.classList.remove('preload')
    }, 300);
});

// Fix NodeList.forEach in IE11
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}


/*
 * AOS.js
 */

import AOS from 'aos';
AOS.init({
    duration : 600,
    easing : 'ease-in-out',
    disable : 'mobile',
});


/*
 * Cookies
 */
import Cookies from 'js-cookie';

let cookieNotice = document.getElementById('cookie-notice');
if(cookieNotice != 'undefined' && cookieNotice != null && Cookies.get('cookie-notice') !== 'true') {
    document.querySelectorAll('.js-cookie_close').forEach(el => {
        el.addEventListener('click', event => {
            event.preventDefault();
            Cookies.set('cookie-notice', 'true', { expires : 7 });
            cookieNotice.classList.add('hide');
        }, false);
    });
}


/*
 * FlashMessage
 */

import FlashMessage from './functions/flash-message';
const flash_message = new FlashMessage();


/*
 * Forms
 */

// FormValidation
import './functions/form-validation';
document.querySelectorAll('.form.js-validate').forEach(form => {
    if (form.querySelector('.g-recaptcha')) {
        recaptcha = document.querySelector("meta[name='recaptcha_site_key']").getAttribute('content');
    } else {
        recaptcha = false;
    }

	new Validate(form, {
		recaptchaSiteKey: recaptcha,
		fieldFeedbackSelector: '.js-field-feedback span',
    });

    // Focus invalid field behaviour
    form.addEventListener('submit', function () {
        let invalidField = form.querySelector('.js-field.is-invalid');
        if (invalidField) {
            scrollToElem(invalidField);
        }
    });
});

// In-field Labels
document.querySelectorAll('.js-infield').forEach(field => {
    field.addEventListener('keyup', event => {
        field.setAttribute('value', field.value);
    });
});


/*
 * Focus
 */

document.querySelectorAll('.js-focus').forEach(elem => {
    let focus = elem.dataset.focus;

    elem.addEventListener('click', event => {
        setTimeout(function () {
            document.getElementById(focus).focus();
        }, 100);
    });
});


/*
 * GoTo Elem
 */

if(typeof(document.querySelector('.js-goto_elem')) != 'undefined' && document.querySelector('.js-goto_elem') != null) {
    document.querySelectorAll('.js-goto_elem').forEach(el => {
        el.addEventListener('click', event => {
            scrollToElem(document.getElementById(el.dataset.elem));
        });
    });
}

// On Page  Load
if(window.location.hash) {
    scrollToElem(document.getElementById(window.location.hash.substring(1)));
}


/*
 * Modal Window
 */

import ModalWindow from './functions/modal-window';
const modal_window = new ModalWindow();


/*
 * StickyHeader
 */

import StickyHeader from './functions/sticky-header';
const sticky_header = new StickyHeader();


/*
 * Swiper
 */

import './vendor/swiper';
document.querySelectorAll('.js-swiper').forEach(swiper => {
    let swiperID = swiper.id;
    let params = swiper.dataset.swiper;
    let mySwiper = new Swiper(swiper, JSON.parse(params));

    // Swiper Height
    document.addEventListener('lazyloaded', function (e) {
        if(swiper.dataset.autoheight)
            mySwiper.updateAutoHeight();
    });
});

// Swiper GOTO
document.querySelectorAll('.js-swiper_goto').forEach(goto => {
    goto.addEventListener('click', event => {
        event.currentTarget.blur();

        let swiperSlider = JSON.parse(goto.dataset.slider);
        let swiperSlide = goto.dataset.slide;

        for (var i = 0; i < swiperSlider.length; i++) {
            let mySwiper = document.getElementById(swiperSlider[i]).swiper;
            mySwiper.slideTo(swiperSlide);
        }
    });
});


/*
 * Tabbing
 */

const handleFirstTab = (e) => {
    if (e.keyCode === 9) {
        document.body.classList.add('has-tabbing-user');

        window.removeEventListener('keydown', handleFirstTab);
        window.addEventListener('mousedown', handleMouseDownOnce);
    }
};

const handleMouseDownOnce = () => {
    document.body.classList.remove('has-tabbing-user');

    window.removeEventListener('mousedown', handleMouseDownOnce);
    window.addEventListener('keydown', handleFirstTab);
};

window.addEventListener('keydown', handleFirstTab);


/*
 * Video
 */

import Video from './functions/video';
const video = new Video();


/* ------------------------------------------------------------------------------------------------------------------------ */
/* VIEWPORT --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

// Define our viewportWidth variable
let viewportWidth;

// Set/update the viewportWidth value
let setViewportWidth = function () {
	viewportWidth = window.innerWidth || document.documentElement.clientWidth;
}

// Log the viewport width into the console
let logWidth = function () {
    if(typeof(document.querySelector('.js-viewport_swap')) != 'undefined' && document.querySelector('.js-viewport_swap') != null) {
        document.querySelectorAll('.js-viewport_swap').forEach(el => {
            let originalElement = el;
            let originalElementClone = el.cloneNode(true);
            let swapElement = document.getElementById(el.dataset.swap);
            let swapElementClone = swapElement.cloneNode(true);

            if (viewportWidth > 768) {
                if (originalElementClone.classList.contains('is-swapped')) {
                    swapElement.parentNode.replaceChild(originalElementClone, swapElement);
                    originalElement.parentNode.replaceChild(swapElementClone, originalElement);

                    originalElementClone.classList.remove('is-swapped');
                }
            } else {
                if (!originalElementClone.classList.contains('is-swapped')) {
                    swapElement.parentNode.replaceChild(originalElementClone, swapElement);
                    originalElement.parentNode.replaceChild(swapElementClone, originalElement);

                    originalElementClone.classList.add('is-swapped');
                }
            }
        });
    }
}

// Set our initial width and log it
setViewportWidth();
logWidth();

// On resize events, recalculate and log
window.addEventListener('resize', function () {
	setViewportWidth();
	logWidth();
}, false);


/* ------------------------------------------------------------------------------------------------------------------------ */
/* SUPPORT FUNCTIONS ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */


/*
 * scrollToElem
 */

const scrollToElem = (elem, offset = 0) => {
    let headerHeight = document.querySelector('.header').offsetHeight;

    if(typeof(document.querySelector('.js-sticky')) != 'undefined' && document.querySelector('.js-sticky') != null) {
        headerHeight += document.querySelector('.js-sticky').offsetHeight;
    }

    const y = elem.getBoundingClientRect().top + window.pageYOffset - headerHeight - offset;

    setTimeout(function () {
        window.scrollTo({
            top : y,
            behavior : 'smooth'
        });
    }, 1);
};


/*
 * elemSiblings
 */

const elemSiblings = n => [...n.parentElement.children].filter(c=>c!=n);


/*
 * GA Events
 */

import './functions/gaEvents';
