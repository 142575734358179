'use strict';

export default class Video {
	constructor() {
		document.querySelectorAll('.js-video_placeholder').forEach(el => {
		    el.addEventListener('click', event => {
		        event.preventDefault();
		        event.stopPropagation();
		        el.classList.add('open');
		    });
		});
	}
}
